import React, { useContext, useLayoutEffect, useState } from "react"
import { Link } from "gatsby"
import MenuContext from "../MenuContext"
import { NavModuleStyles } from "./NavModuleStyles"
import { motion } from "framer-motion"
import { menuItems } from "./NavConstants"
import {
  barOneVariants,
  barTwoVariants,
  barThreeVariants,
  menuList,
  menuListSlideUp,
  transition,
  menuParent,
  contactTitle,
  contactContainer,
  contactItem,
  contactPs,
} from "./NavAnim"
import { UseSiteMetadata } from "../../hooks/useSiteMetadata"

import { FaPhoneAlt, FaFax } from "react-icons/fa"
import { GrMail } from "react-icons/gr"
import { RiTimeLine, RiTimeFill } from "react-icons/ri"

// import video1 from "../../videos/chmervideo-comp.mp4"
import video2 from "../../videos/millingmachine-comp.mp4"
import video3 from "../../videos/feelermachine-comp.mp4"
import pic1 from "../../images/homeSlide/cpupic.png"
import pic2 from "../../images/homeSlide/outechcompanypic-min.png"

const NavModule = () => {
  const [isOpen, setNav] = useContext(MenuContext)
  const [scrollActive, setScrollActive] = useState(false)

  const toggleNav = () => {
    setNav((isOpen) => !isOpen)
  }
  const [bg, setBg] = useState(null)
  const [isVideo, setIsVideo] = useState(false)

  const pickBackground = (link) => {
    if (link === "회사소개") {
      setIsVideo(false)
      setBg(pic1)
    }
    if (link === "방전가공") {
      setIsVideo(true)
      setBg(video2)
    }
    if (link === "절삭가공") {
      setIsVideo(true)
      setBg(video3)
    }
    if (link === "오시는길") {
      setIsVideo(false)
      setBg(pic2)
    }
  }

  useLayoutEffect(() => {
    const changeButtonBackground = () => {
      if (window.scrollY >= 800) {
        setScrollActive(true)
      } else {
        setScrollActive(false)
      }
    }
    window.addEventListener("scroll", changeButtonBackground)
    changeButtonBackground()
    return () => window.removeEventListener("scroll", changeButtonBackground)
  }, [])

  // const { title } = UseSiteMetadata()
  const title = "OU-TECH"

  return (
    <NavModuleStyles>
      <div className="nav">
        <div className="container">
          {/* Menu Button */}
          <div
            className={
              scrollActive ? "buttonWrapper scrollActive" : "buttonWrapper"
            }
          >
            <motion.button
              initial="closed"
              animate={isOpen ? "open" : "closed"}
              onClick={toggleNav}
              aria-label={isOpen ? "Close Menu" : "Open Menu"}
              className={`hamburger${isOpen ? " open" : ""}`}
            >
              <motion.span
                className="bar"
                variants={barOneVariants}
              ></motion.span>
              <motion.span
                className="bar"
                variants={barTwoVariants}
              ></motion.span>
              <motion.span
                className="bar"
                variants={barThreeVariants}
              ></motion.span>
            </motion.button>
          </div>

          {/* Home Logo */}
          {title && (
            <div className={scrollActive ? "logo scrollActive" : "logo"}>
              <Link to="/">
                {title}
                <span>.</span>
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Menu Page */}
      <motion.div
        initial="closed"
        animate={isOpen ? "open" : "closed"}
        variants={menuList}
        transition={{ type: "ease", stiffness: 50, velocity: 50 }}
        className="menu"
      >
        {isVideo ? (
          <motion.video
            src={bg}
            type="video/mp4"
            autoPlay
            loop
            muted
            playsInline
            className="menu__background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.4 }}
            transition={{
              type: "ease",
              stiffness: 60,
              duration: 1.5,
            }}
          ></motion.video>
        ) : (
          <motion.img
            src={bg}
            className="menu__background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.4 }}
            transition={{
              type: "ease",
              stiffness: 60,
              duration: 1,
            }}
          />
        )}
        <div className="menu__links">
          <motion.ul
            variants={menuParent}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
          >
            {menuItems.map((item, index) => (
              <motion.li
                onClick={toggleNav}
                key={index}
                variants={menuListSlideUp}
                transition={transition}
                onMouseEnter={() => pickBackground(item.text)}
                onMouseOut={() => pickBackground(null)}
              >
                {item.text === "문의하기" ? (
                  <a href={item.path}>
                    {item.text}
                    <span>.</span>
                  </a>
                ) : (
                  <Link to={item.path} activeClassName="menu__item--active">
                    {item.text}
                    <span>.</span>
                  </Link>
                )}
              </motion.li>
            ))}
          </motion.ul>
        </div>

        <div className="contact__info">
          <motion.h2
            className="contact__title"
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            variants={contactTitle}
          >
            고객지원센터
          </motion.h2>
          <motion.div
            className="contact__container"
            variants={contactContainer}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
          >
            <motion.p className="phone" variants={contactItem}>
              <FaPhoneAlt className="icon" />
              031-355-6526~7
            </motion.p>
            <motion.p className="fax" variants={contactItem}>
              <FaFax className="icon" />
              031-355-6528
            </motion.p>
            <motion.p className="email" variants={contactItem}>
              <GrMail className="icon" />
              outech@naver.com
            </motion.p>
            <motion.p className="time" variants={contactItem}>
              <RiTimeFill className="icon" />
              월~금 : 오전 9:00 ~ 오후 6:00
            </motion.p>
            <motion.p className="time" variants={contactItem}>
              <RiTimeLine className="icon" />
              토(격주) : 오전 9:00 ~ 오후 2:00
            </motion.p>
          </motion.div>
          <motion.p className="ps" variants={contactPs}>
            AS요청은 고객지원센터를 이용하는 것이 빠른 대응과 효과적인 대처가
            가능합니다.
          </motion.p>
        </div>
      </motion.div>
    </NavModuleStyles>
  )
}

export default NavModule
