export const barOneVariants = {
  open: {
    y: [0, 10, 10, 10, 10],
    rotate: [0, 0, 0, 45, 45],
  },
  closed: {
    y: [10, 10, 0, 0, 0],
  },
}

export const barTwoVariants = {
  open: {
    opacity: 0,
  },
  closed: {
    opacity: 1,
  },
}

export const barThreeVariants = {
  open: {
    y: [0, -10, -10, -10, -10],
    rotate: [0, 0, 0, -45, -45],
  },
  closed: {
    y: [-10, -10, 0, 0, 0],
  },
}

export const menuList = {
  open: {
    x: 0,
  },
  closed: {
    x: "calc(var(--menuWidth) * -1)",
  },
}

// Menu links
export const menuParent = {
  open: {
    transition: {
      delayChildren: 0.01,
      staggerChildren: 0.1,
    },
  },
}
export const menuListSlideUp = {
  closed: { y: 30, opacity: 0 },
  open: { y: 0, opacity: 1 },
}

export const transition = {
  duration: 0.8,
  ease: [0.6, -0.5, 0.01, 0.9],
}

export const contactTitle = {
  closed: { opacity: 1, scale: 0 },
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
}

export const contactContainer = {
  closed: { opacity: 1, scale: 0 },
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
}

export const contactItem = {
  closed: { y: 20, opacity: 0 },
  open: {
    y: 0,
    opacity: 1,
  },
}

export const contactPs = {
  closed: { opacity: 1, scale: 0 },
  open: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
}
