import styled from "styled-components"

export const FooterStyles = styled.footer`
  background-color: #201f1e;
  padding: calc(var(--sectionPadding) * 0.3) 25px 40px 25px;

  .container {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      align-items: center;
    }
  }

  .footer__title {
    color: var(--primary);
    font-weight: 900;
  }

  .footer__menu {
    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 20px 0;

      @media (min-width: 768px) {
        display: flex;
      }
    }

    li {
      margin-top: 5px;
      margin-bottom: 5px;

      @media (min-width: 768px) {
        margin-left: 6px;
        margin-right: 6px;
      }

      a {
        color: #fff;
        text-decoration: none;
        text-transform: capitalize;
        font-weight: 700;
        font-size: 20px;
        transition: color 0.3s ease;

        @media (min-width: 375px) {
          font-size: 22px;
        }

        span {
          color: var(--primary);
        }
      }

      &:hover,
      &:focus {
        a {
          color: var(--primary);
        }
      }
    }
  }

  .footer__content {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--primary);
    ul {
      margin: 0;
      padding: 8px 0;
      list-style: none;
      li {
        font-size: 11px;
      }
    }

    @media (min-width: 768px) {
      ul {
        display: flex;
        justify-content: center;

        li {
          font-size: 13px;
          margin-right: 8px;
          border-right: 1px solid var(--primary);
          padding: 0 10px;

          &:last-child {
            border-right: none;
          }
        }
      }
    }

    @media (min-width: 1152px) {
      li {
        font-size: 14px;
      }
    }
  }

  .copyright {
    font-size: 13px;

    p {
      margin: 0;

      a {
        color: #fff;
        font-weight: 700;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover,
        &:focus {
          color: var(--primary);
        }
      }
      span {
        color: var(--primary);
      }
    }
  }
`
