import * as React from "react"
import { Link } from "gatsby"
import { menuItems, socialItems } from "../../constants/links"
import { FooterStyles } from "./FooterStyles"

const Footer = () => {
  return (
    <FooterStyles>
      <div className="container">
        <h3 className="footer__title">(주)오유/오유테크코리아</h3>
        <div className="footer__menu">
          <ul>
            {menuItems.map((item, index) => {
              return (
                <li key={index}>
                  {item.text === "문의하기" ? (
                    <a href={item.path}>
                      {item.text}
                      <span>.</span>
                    </a>
                  ) : (
                    <Link to={item.path} activeClassName="menu__item--active">
                      {item.text}
                      <span>.</span>
                    </Link>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
        <div className="footer__content">
          <ul>
            <li>경기도 화성시 마도면 마도공단로 1길 62 마도산단 2B-9L</li>
            <li>대표이사 오 경 명</li>
            <li>사업자등록번호: 133-11-68460 (주)오유/오유테크코리아</li>
            <li>
              Tel: <strong>031-355-6526~7</strong>
            </li>
            <li>Fax: 031-355-6528</li>
          </ul>
          <ul>
            <li>
              E-mail: <strong>outech@naver.com</strong>
            </li>
            <li>
              부산 지역사무소: 부산시 중구 대청로 121-1, 101동 217호 (Tel:
              051-319-0515)
            </li>
          </ul>
        </div>
        <div className="copyright">
          <p>
            COPYRIGHT&copy; <span>OUTECH KOREA</span>
            <span>. </span>
            All Rights Reserved
            <span>.</span>
          </p>
        </div>
      </div>
    </FooterStyles>
  )
}

export default Footer
