export const menuItems = [
  {
    path: "/",
    text: "회사소개",
  },
  {
    path: "/edm",
    text: "방전가공",
  },
  {
    path: "/lathes",
    text: "절삭가공",
  },
  {
    path: "mailto:outech@naver.com?subject=안녕하세요 문의드립니다.",
    text: "문의하기",
  },
]
