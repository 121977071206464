import styled from "styled-components"

export const NavModuleStyles = styled.nav`
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding: 20px var(--borderSpacing) 30px var(--borderSpacing);
    /* border-bottom: 1px solid #ffffff6a; */

    @media (min-width: 1024px) {
      padding-top: 50px;
    }

    @media (min-width: 1440px) {
      padding-top: 70px;
    }
  }

  .hamburger {
    background-color: transparent;
    border: none;
    width: 30px;
    height: 30px;
    padding: 0;
    cursor: pointer;
    outline: none;

    &:focus {
      border: none;
      outline: none;
    }

    .bar {
      display: block;
      background-color: #fff;
      height: 2px;
      border-radius: 2px;
      z-index: 999;

      &:nth-of-type(2) {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
  .scrollActive {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    transition: all 0.4s ease-in-out;
    background-color: #eeeeeed3;
    border-radius: 30px;
  }

  .buttonWrapper.scrollActive {
    padding: 15px;
    .bar {
      background-color: #000;
    }
  }

  .logo {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.5px;

    a {
      color: #fff;
      text-decoration: none;
      transition: color 0.3s ease;
    }

    @media (min-width: 1024px) {
      font-size: 25px;
    }

    span {
      color: var(--primary);
    }

    &:hover,
    &:focus {
      a {
        color: var(--primary);
      }
    }
  }

  .logo.scrollActive {
    padding: 15px 20px;
    a {
      color: #000;
    }

    &:hover,
    &:focus {
      a {
        color: var(--primary);
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  /* Menu lists */
  .menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    background-color: #000;
    width: var(--menuWidth);
    padding: 30px var(--borderSpacing);
    transform: translateX(calc(var(--menuWidth) * -1));
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: 99;

    @media (min-width: 1224px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .menu__background {
      position: absolute;
      object-fit: cover;
      opacity: 0.4;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: var(--menuWidth);
      z-index: -1;
      object-position: center;
    }

    .menu__links {
      display: flex;
      align-items: center;
      margin-top: 100px;

      @media (min-width: 1224px) {
        margin-top: 0;
      }
    }

    /* Contact Info */

    .contact__info {
      position: relative;
      font-size: 11px;
      padding: 5px 15px 5px;
      /* background: #ffffff1c; */
      border-radius: 20px;
      /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px; */

      @media (min-width: 1224px) {
        position: absolute;
        top: 25%;
        right: 10%;
        height: 400px;
        width: 670px;
        padding: 30px;
        font-size: 15px;
        text-align: center;
      }

      .contact__container {
        @media (min-width: 1224px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 5px;
        }

        p {
          font-weight: 600;
          /* margin: 0 auto; */
          @media (min-width: 1224px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background: #ffffffb0;
            width: 200px;
            padding: 10px;
            color: black;
            box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
          }

          .icon {
            font-size: 15px;
            margin: 2px 5px 2px;
            transform: translateY(40%);

            @media (min-width: 1224px) {
              margin-bottom: 15px;
            }
          }
        }

        p.time {
          @media (min-width: 1224px) {
            width: 302px;
          }
        }
      }
    }

    .ps {
      font-style: italic;
    }

    h2 {
      font-size: 15px;
      @media (min-width: 1224px) {
        font-size: 27px;
        letter-spacing: 4px;
        margin-bottom: 7px;
        font-style: italic;
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      letter-spacing: -0.5px;
      font-size: 28px;

      @media (min-width: 375px) {
        font-size: 36px;
      }

      @media (min-width: 414px) {
        font-size: 46px;
      }

      a {
        color: #fff;
        font-weight: 700;
        text-decoration: none;

        &:hover {
          color: var(--primary);
          transition: all 0.2s;
          font-size: 55px;
        }
      }

      span {
        color: var(--primary);
      }
    }
  }
`
